import React, {Component} from 'react';
import PropTypes from 'prop-types';
import logo from './images/maldiniLogo.png';
import $ from 'jquery';
import firebase from './firebase'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'


function validatePhoneNumber(inputtxt) {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if(inputtxt.value.match(phoneno)) {
        return true;
    }
    else {
        return false;
    }
}
function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
        textbox.addEventListener(event, function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    });
}
function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export default class ProductEnded extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            lastName: '',
            email: '',
            country: 'Argentina',
            birth: '2000-12-25',
            tel: '',
            uid: '',
            ID: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
            hasSuscribed: false,
        };
    }

    componentDidMount() {
        if (this.state.friendUID){
            var db = firebase.firestore();
            const context = this;
            db.collection('users').doc(this.state.friendUID).get()
                .then(function (doc) {
                    if (doc.exists) {
                        context.setState({
                            headerText:'Esta campaña ya finalizo 😞 pero podes seguirnos en nuestras redes para enterarte sobre nuevos beneficios',
                        })
                    }
                    else {
                        context.setState({
                            headerText:'Esta campaña ya finalizo 😞 pero podes seguirnos en nuestras redes para enterarte sobre nuevos beneficios',
                        })
                    }
                })
        }
        else {
            this.setState({
                headerText:'Esta campaña ya finalizo 😞 pero podes seguirnos en nuestras redes para enterarte sobre nuevos beneficios',
            })
        }



    }


    submitNewsletter = () => {

        if (!this.state.hasSuscribed){
            if (validateEmail(this.state.email)){
                var db = firebase.firestore();
                var context = this;
                context.setState({hasSuscribed: true});
                db.collection('newsletters').where('email','==',this.state.email).where('campaign','==', 'maldini').get().then(function (snapshot) {
                    if (snapshot.size>0){
                        $(".form-group").removeClass("has-success");
                        $('#js-subscribe-result').fadeIn('slow').html('<p class="help-block text-success">' + '!Felicitaciones! Ya estas suscripto' + '</p>').delay(3000).fadeOut('slow');
                    } else {
                        db.collection('newsletters').add({email: context.state.email, campaign: 'maldini'}).then(function (docRef) {
                            $(".form-group").removeClass("has-success");
                            $('#js-subscribe-result').fadeIn('slow').html('<p class="help-block text-success">' + '!Felicitaciones! Ya estas suscripto' + '</p>').delay(3000).fadeOut('slow');
                        });
                    }
                });


            } else {
                $(".input-group").addClass('animated shake');
                setTimeout(function () {
                    $(".input-group").removeClass('animated shake');
                }, 1000)
            }
        }




    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };




    render() {
        return (
            <div>
                <div id="loading">
                    <div id="loading-center">
                        <div id="loading-center-absolute">
                            <div className="object" id="object_four"></div>
                            <div className="object" id="object_three"></div>
                            <div className="object" id="object_two"></div>
                            <div className="object" id="object_one"></div>
                        </div>
                    </div>
                </div>
                <header className="row" id="header">
                    <nav className="navbar navbar-default navbar-fixed-top">
                        <div className="container">

                            <div className="navbar-header">
                                <a className="navbar-brand" href="#header"><img src={logo} alt=""/></a>
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                        data-target="#main-navbar" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="main-navbar">

                            </div>

                        </div>

                    </nav>
                    <div className="top-banner row m0 text-center fadeInOnLoad">
                        <div className="container">
                            <h2>
                                {this.state.headerText}
                              {/*  <br/>
                                <br/>*/}
                               {/* <a href={'https://www.thekitmaker.com'}>www.thekitmaker.com</a>*/}
                            </h2>
                            <div className="row m0 social-links">
                                <ul className="nav">
                                    <li className="wow fadeInUp"><a href="https://www.facebook.com/maldinibar/"><i className="fab fa-facebook"/></a></li>
                                    <li className="wow fadeInUp" data-wow-delay="0.1s"><a href="https://www.instagram.com/maldini.ba/"><i
                                        className="fab fa-instagram"/></a></li>
                                </ul>
                            </div>
                            <div className="row section-header wow fadeInUp">
                                <h2>novedades</h2>
                                <p>Dejanos tu e-mail para enterarte de las ultimas novedades y futuras promociones</p>
                            </div>
                            <div id="subscribeform" className="row newsletter-form">
                                <div className="input-group">
                                    <input type="email" className="form-control" name="email"
                                           placeholder="Ingresa tu e-mail" value={this.state.email} onChange={this.handleChange('email')}/>
                                    <span className="input-group-addon">
                                            <button onClick={this.submitNewsletter} id="js-subscribe-btn">
                                                <img src="images/right-angle-white.png" alt=""/>
                                                </button>
                                        </span>
                                </div>
                                <div id="js-subscribe-result" className="text-center"
                                     data-success-msg="Almost finished. Please check your email and verify."
                                     data-error-msg="Oops. Something went wrong.">
                                    <p>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            </div>
        );
    }
}