import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Product from "./Product";
import WOW from 'wowjs';
import Form from "./Form";
import firebase from './firebase'
import $ from 'jquery';
import {formatPhoneNumber} from "react-phone-number-input";
import Cookies from 'universal-cookie';
import ProductEnded from "./ProductEnded";
import {CSVLink} from "react-csv";

function validatePhoneNumber(inputtxt) {

    var phoneno = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g;
    if(inputtxt.match(phoneno)) {
        return true;
    }
    else {
        return false;
    }
}

function getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    }

    if(mm<10) {
        mm = '0'+mm
    }

    today = yyyy + '-' + mm + '-' + dd;

    return today;
}
function addClassToId(id, className) {
    var element, name, arr;
    element = document.getElementById(id);
    name = className;
    arr = element.className.split(" ");
    if (arr.indexOf(name) == -1) {
        element.className += " " + name;
    }
}
function removeClassToId(id, classname) {
    var element = document.getElementById(id);
    element.className = element.className.replace(classname, "");

}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            lastName: '',
            email: '',
            country: 'Argentina',
            birth: '',
            tel: '',
            uid: '',
            ID: '',
            dni: '',
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
            headerText: '',
            login: false,
            arr: [],
            headers : [
                { label: "Name", key: "name" },
                { label: "Last Name", key: "lastName" },
                { label: "Birth", key: "birth" },
                { label: "Email", key: "email" },
            ],
        };
    }
    onSubmit = () => {

        if (this.state.name && this.state.lastName && this.state.email && this.state.birth && this.state.dni){
             if (validateEmail(this.state.email)){

                     addClassToId('load-user', 'sending');
                     var db = firebase.firestore();
                     const context = this;
                     db.collection('users').where('campaign', '==', 'getviraltest').where('email','==', this.state.email).get()
                         .then(function (snapshot) {
                             if (snapshot.size > 0){
                                 removeClassToId('load-user', 'sending');
                                 addClassToId('load-user', 'is-success');
                                 removeClassToId("js-personal-info","slide-in-right");
                                 addClassToId("js-personal-info", "slide-out-left");
                                 addClassToId("js-submit","slide-in-right2");
                                 snapshot.forEach(function(doc) {
                                     // doc.data() is never undefined for query doc snapshots
                                     context.setState({ID: doc.id})
                                 });

                             }else {
                                 db.collection('users').add({
                                     name: context.state.name,
                                     lastName: context.state.lastName,
                                     email: context.state.email,
                                     birth: context.state.birth,
                                     tel: context.state.tel,
                                     dni: context.state.dni,
                                     friendUID: context.state.friendUID,
                                     campaign: 'getviraltest',
                                     validated: false,
                                     shareTo: 0,
                                     shareToRegister: 0,
                                 }).then(function (doc) {
                                     context.setState({ID: doc.id});
                                     removeClassToId('load-user', 'sending');
                                     addClassToId('load-user', 'is-success');
                                     removeClassToId("js-personal-info","slide-in-right");
                                     addClassToId("js-personal-info", "slide-out-left");
                                     addClassToId("js-submit","slide-in-right2");

                                     if (context.state.friendUID){
                                         var friendRef = db.collection('users').doc(context.state.friendUID);
                                         db.runTransaction(function(transaction) {
                                             return transaction.get(friendRef).then(function(doc) {
                                                 if (!doc.exists) {
                                                     throw "Document does not exist!";
                                                 }

                                                 var newShare = doc.data().shareToRegister + 1;
                                                 transaction.update(friendRef, { shareToRegister: newShare });
                                             });
                                         })

                                     }



                                 })
                             }
                         });




             }
             else {
                 addClassToId('formEmail', 'has-error');
             }
         }
         else {
             addClassToId('formName', 'has-error');
             addClassToId('formLastName', 'has-error');
             addClassToId('formEmail', 'has-error');
             addClassToId('formBirth', 'has-error');
           /*  addClassToId('formTel', 'has-error');*/
             addClassToId('formCountry', 'has-error');
         }
    };
    handleChangeTel = (value) => {

        if (value){
            if (value.length >= 0) {
                this.setState({ tel: formatPhoneNumber(value, 'International') })
            } else {
                this.setState({
                    tel: value,
                })
            }
        }

    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
        removeClassToId('formName', 'has-error');
        removeClassToId('formLastName', 'has-error');
        removeClassToId('formEmail', 'has-error');
        removeClassToId('formBirth', 'has-error');
       /* removeClassToId('formTel', 'has-error');*/
        removeClassToId('formCountry', 'has-error');
    };

    componentDidMount() {

       var context = this;
       /* var db = firebase.firestore();*/
       /*var arrUsers = [];
       db.collection('users').where('campaign', '==','maldini').get().then(function (snap) {
           console.log(snap.size);
           snap.forEach(function (user) {
               var newUser = {
                   name: user.data().name,
                   lastName: user.data().lastName,
                   birth: user.data().birth,
                   email: user.data().email,
               };
               arrUsers.push(newUser)
           });
           context.setState({arr: arrUsers})
       });*/

       const cookies = new Cookies();
       var db = firebase.firestore();
       var campRef = db.collection('campaigns').doc('getviraltest');
       var hasUsed = cookies.get('hasUsed');

       db.runTransaction(function (transaction) {
           return transaction.get(campRef).then(function (camp) {
               if (!camp.exists) {
                   throw "Document does not exist!";
               }
               var newTraffic = camp.data().traffic + 1;

               transaction.update(campRef, {traffic: newTraffic});
               return newTraffic;

           });
       }).then(function (newPopulation) {

       }).catch(function (err) {
           // This will be an "population is too big" error.
           console.error(err);
       });

       if (!hasUsed){
           let d = new Date();
           d.setTime(d.getTime() + (365*24*60*60*1000));
           cookies.set('hasUsed',true, {expires: d});
           db.runTransaction(function (transaction) {
               return transaction.get(campRef).then(function (camp) {
                   if (!camp.exists) {
                       throw "Document does not exist!";
                   }
                   var newTrafficUsers = camp.data().trafficUsers + 1;

                   var newConsumerShare = camp.data().consumerShare;
                   var newBrandShare = camp.data().brandShare;

                   if (context.state.friendUID){
                       newConsumerShare = newConsumerShare + 1
                   }
                   else {
                       newBrandShare = newBrandShare + 1;
                   }

                   transaction.update(campRef, {trafficUsers: newTrafficUsers, consumerShare: newConsumerShare, brandShare: newBrandShare});
                   return newTrafficUsers;

               });
           }).then(function (newPopulation) {


               if (context.state.friendUID){
                   var friendRef = db.collection('users').doc(context.state.friendUID);
                   db.runTransaction(function(transaction) {
                       return transaction.get(friendRef).then(function(doc) {
                           if (!doc.exists) {
                               throw "Document does not exist!";
                           }

                           var newShare = doc.data().shareTo + 1;
                           transaction.update(friendRef, { shareTo: newShare });
                       });
                   })

               }



           }).catch(function (err) {
               // This will be an "population is too big" error.
               console.error(err);
           });

       }


        new WOW.WOW({
            live: false
        }).init();

        firebase.auth().getRedirectResult().then(function(result) {
            if (result.credential) {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // ...
            }
            $("#loading").fadeOut();
            $("#loading .object").delay(700).fadeOut("slow");
            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);

            // The signed-in user info.
            var user = result.user;
            console.log(result.user);
            var splitedString = user.displayName.split(' ');

            var firstName = splitedString[0];
            var lastName = '';

            for (var i=1; i<splitedString.length; i++){
                if (i === 1){
                    lastName = splitedString[i];
                }
                else {
                    lastName = lastName + ' ' + splitedString[i];
                }
            }
            context.setState({
                name: firstName,
                lastName: lastName,
                email: user.email,
                uid: user.uid,
                login: true,
            });

            console.log(firstName)
        }).catch(function(error) {
            $("#loading").fadeOut();
            $("#loading .object").delay(700).fadeOut("slow");
            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });

    }

    render() {
        return (
            <div className="App">
              {/*  <ProductEnded/>*/}
                {this.state.login ? <Form handleChange={this.handleChange} handleChangeTel={this.handleChangeTel} onSubmit={this.onSubmit}
                name={this.state.name} lastName={this.state.lastName} birth={this.state.birth} email={this.state.email} country={this.state.country} tel={this.state.tel} ID={this.state.ID}/> : <Product/>}
               {/* <CSVLink data={this.state.arr} headers={this.state.headers}>Download me</CSVLink>*/}
            </div>
        );
    }
}

export default App;
